import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { DragDropContext, Droppable} from 'react-beautiful-dnd';
import { reorder, move, getListStyle } from './DragDropCommonFunctions';
import DragDropCard from './DragDropCard';


class StepTwo extends Component {
    state = {
        unsure: [],
        devNeeded: [],
        competency: [],
        strength: [],
    };

    onDragEnd = result => {
        const { source, destination } = result;

        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                this.state[source.droppableId],
                source.index,
                destination.index
            );

            let state = { items };

            if (source.droppableId === 'unsure') {
                state = { unsure: items };
            }

            if (source.droppableId === 'devNeeded') {
                state = { devNeeded: items };
            }

            if (source.droppableId === 'competency') {
                state = { competency: items };
            }

            if (source.droppableId === 'strength') {
                state = { strength: items };
            }

            this.setState(state);

        } else {
            const result = move(
                this.state[source.droppableId],
                this.state[destination.droppableId],
                source,
                destination
            );

            this.setState({
                unsure: result.unsure ? result.unsure : this.state.unsure,
                devNeeded: result.devNeeded ? result.devNeeded : this.state.devNeeded,
                competency: result.competency ? result.competency : this.state.competency,
                strength: result.strength ? result.strength : this.state.strength,
            });
        }
    };

    componentDidMount() {
        this.setState({
            unsure: this.props.history.location.state.unsure
        })
    }

    render() {
        if (!this.state.unsure) {
            return <div className="step-two">
                Loading...
            </div>
        } else {
            return (
                <div className="step-two">
                    <div className="content-padding">
                    <div className="center-text">
                        <h4>Instructions:</h4>
                        <p>
                        • Now distribute the cards to the columns below depending on how competent you currently feel in these skills.<br/>
                        • Try to limit the cards in the 'Development Needed' column to an actionable number - five cards maximum.
                    </p>
                    </div>
                    </div>
                    <div className="button-align">
                    <Link to={{
                        pathname: "/stepthree",
                        state: { cards: this.state.devNeeded },
                    }}><button>Next Step ></button></Link>
                    </div>
                    <div className="content">
                        <DragDropContext onDragEnd={this.onDragEnd}>
                            <Droppable droppableId="unsure">
                                {provided => (
                                    <div
                                        ref={provided.innerRef}
                                        style={getListStyle}>
                                        <h2>Unsure</h2>
                                        {this.state.unsure.length ? (this.state.unsure.map((item, index) => (
                                            DragDropCard(item, index)
                                        ))) : ''}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                            <Droppable droppableId="devNeeded">
                                {provided => (
                                    <div
                                        ref={provided.innerRef}
                                        style={getListStyle}>
                                        <h2>Development Needed</h2>
                                        {this.state.devNeeded.length ? (this.state.devNeeded.map((item, index) => (
                                            DragDropCard(item, index)
                                        ))) : ''}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                            <Droppable droppableId="competency">
                                {provided => (
                                    <div
                                        ref={provided.innerRef}
                                        style={getListStyle}>
                                        <h2>Competency</h2>
                                        {this.state.competency.length ? (this.state.competency.map((item, index) => (
                                            DragDropCard(item, index)
                                        ))) : ''}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                            <Droppable droppableId="strength">
                                {provided => (
                                    <div
                                        ref={provided.innerRef}
                                        style={getListStyle}>
                                        <h2>Strength</h2>
                                        {this.state.strength.length ? (this.state.strength.map((item, index) => (
                                            DragDropCard(item, index)
                                        ))) : ''}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </div>

            );
        }
    }
}

export default StepTwo;