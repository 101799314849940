export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

const grid = 8;

export const getItemStyle = (draggableStyle, category) => ({
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    background: category === "Technical Skills" ? '#a4bdd6' : (category === "General Skills" ? '#b0a0d9' :  category === "Cross-Functional Skills" ? '#bddfb3' : "#ffffff"),

    ...draggableStyle
});

export const getListStyle = ({
    background: '#F3F4F5',
    padding: grid,
    width: 240
});