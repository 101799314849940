import React from 'react'
import { Link } from 'react-router-dom'

export default function Header() {
    return (
        <div className="header">
            <div className="title">Dev Skills</div>
            <div></div>
            <Link to="/">Start Again</Link>
        </div>
    )
}