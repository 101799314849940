import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class Main extends Component {
    render() {
        return(
            <div className="main content-padding button-align">
                <p className="subtitle">A skill progression and goal setting framework for developers.</p>
                <p>I am a...</p><br/>
                <Link to={{
                    pathname: "/stepone",
                    state: { role: "junior" },
                }}><button>Junior Developer</button></Link><br/><br/>
                <Link to={{
                    pathname: "/stepone",
                    state: { role: "general" },
                }}><button>General Developer</button></Link>
            </div>
        )
    }
}

export default Main;