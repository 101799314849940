import React, { Component } from 'react';
import { PDFExport } from '@progress/kendo-react-pdf';

class StepFour extends Component {

    state = {
        data: []
    }

    componentDidMount() {
        const data = Array.from(this.props.history.location.state.data)
        this.setState({
            data,
        })
    }

    exportPDFWithComponent = () => {
        this.pdfExportComponent.save();
    }

    render() {
        const dateStart = new Date();
        const dateEnd = new Date();
        dateEnd.setDate(dateStart.getDate() + 14)

        if (!this.state.data) {
            return <div className="step-four">
                Loading...
            </div>
        } else {
            return (
                <div className="step-four">
                    <PDFExport 
                        ref={(component) => {
                            this.pdfExportComponent = component
                        }} 
                        paperSize="A4"
                        scale={0.6} 
                        margin="2cm"
                        creator="Dev Skills"
                        fileName="Fortnightly Improvement Goals"
                        >
                        <h2>Fortnightly Improvement Goals</h2>
                        <h3>{dateStart.toDateString()} - {dateEnd.toDateString()}</h3>
                        {this.state.data.map(card => {
                            return <div className="ways-to-improve" key={card.id}>
                            <div className="pdf-column-left">
                                <h4>{card.title}</h4>
                                {card.category} - {card.subcategory}
                                <h5>{card.description}</h5>
                            </div>
                            <div className="pdf-column-right">
                                <h4>I'll Know That I Understand This When I Can...:</h4>
                                <p>{card.value}</p>
                            </div>
                        </div>
                        })}
                    </PDFExport>
                    <div className="button-align">
                        <button onClick={this.exportPDFWithComponent}>Download PDF</button>
                    </div>
                </div>
            );
        }
    }
}

export default StepFour;

