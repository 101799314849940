import React, { Component } from 'react';
import { HashRouter, Route } from 'react-router-dom';
import './App.css';

import Header from './components/Header';
import Main from './components/Main';
import StepOne from './components/StepOne';
import StepTwo from './components/StepTwo';
import StepThree from './components/StepThree';
import StepFour from './components/StepFour';

class App extends Component {
  render() {
    return (
      <div className="App">
        <HashRouter>
          <React.Fragment>
            <Header/>
            <Route path="/" exact component={Main} />
            <Route path="/stepone" component={StepOne}/>
            <Route path="/steptwo" component={StepTwo}/>
            <Route path="/stepthree" component={StepThree}/>
            <Route path="/stepfour" component={StepFour}/>
          </React.Fragment>
        </HashRouter>
      </div>
    );
  }
}

export default App;