import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { getItemStyle } from './DragDropCommonFunctions';


export default (item, index) => {
    return <Draggable
        key={item.id}
        draggableId={item.id}
        index={index}>
        {(provided, snapshot) => (
            <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getItemStyle(
                    provided.draggableProps.style,
                    item.category
                )}>
                <h4>{item.title}</h4>
                {item.category} - {item.subcategory}
                <h5>{item.description}</h5>
            </div>
        )}
    </Draggable>
}