import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

class StepThree extends Component {
    state = {
        cards: [],
        redirect: false
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const form = e.target.elements
        const cards = this.state.cards
        let formData = [];
        let formArray = Array.from(form)
        formArray.slice(0, -1)
            .map(card => {
                const stateCard = cards.find(x => x.id === card.id)
                const input = {
                    "id": stateCard.id,
                    "title": stateCard.title,
                    "category": stateCard.category,
                    "subcategory": stateCard.subcategory,
                    "description": stateCard.description,
                    "value": card.value
                }
                return formData.push(input)
            })
        this.setState({
            formData: formData,
            redirect: true
        })
    }

    componentDidMount() {
        this.setState({
            cards: this.props.history.location.state.cards
        })
    }

    render() {
        if (!this.state.cards) {
            return <div className="step-three">
                Loading...
            </div>
        } else if (this.state.redirect) {
            return <Redirect
                to={{
                    pathname: "/stepfour",
                    state: { data: this.state.formData }
                }}
            />
        } else return (
            <div className="step-three">
                <div className="content-padding">
                    <p className="center-text">
                            How do you plan to improve these skills over the next fortnight?
                        </p><br /><br />
                        <form onSubmit={this.handleSubmit}>
                            {this.state.cards.map((card, index) => {
                                return <div className="ways-to-improve" key={card.id}>
                                    <div className="improve-columns">
                                        <h4>{card.title}</h4>
                                        {card.category} - {card.subcategory}
                                        <h5>{card.description}</h5>
                                    </div>
                                    <div className="improve-columns">
                                        <div>
                                            <h4>I'll Know That I Understand This When I Can...:</h4>
                                            <textarea name="understanding" title={card.title} id={card.id} category={card.category} subcategory={card.category} description={card.description} />
                                        </div>
                                    </div>
                                </div>
                            })}
                            <div className="button-align"><button>Submit</button></div>
                        </form>
                </div>
            </div>
        );
    }
}

export default StepThree;