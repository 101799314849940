import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { savePDF } from '@progress/kendo-react-pdf';
import generalCards from '../resources/general-developer-cards.json';
import juniorCards from '../resources/junior-developer-cards.json';
import { reorder, move, getListStyle } from './DragDropCommonFunctions'
import DragDropCard from './DragDropCard';

class StepOne extends Component {
    state = {
        unsure: [],
        niceToHave: [],
        significant: [],
        essential: [],
    };

    onDragEnd = result => {
        const { source, destination } = result;

        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                this.state[source.droppableId],
                source.index,
                destination.index
            );

            let state = { items };

            if (source.droppableId === 'unsure') {
                state = { unsure: items };
            }

            if (source.droppableId === 'niceToHave') {
                state = { niceToHave: items };
            }

            if (source.droppableId === 'significant') {
                state = { significant: items };
            }

            if (source.droppableId === 'essential') {
                state = { essential: items };
            }

            this.setState(state);

        } else {
            const result = move(
                this.state[source.droppableId],
                this.state[destination.droppableId],
                source,
                destination
            )

            this.setState({
                unsure: result.unsure ? result.unsure : this.state.unsure,
                niceToHave: result.niceToHave ? result.niceToHave : this.state.niceToHave,
                significant: result.significant ? result.significant : this.state.significant,
                essential: result.essential ? result.essential : this.state.essential,
            });
        }
    };

    exportPDFWithMethod = () => {
        savePDF(ReactDOM.findDOMNode(this.unsure), { paperSize: 'A4', scale: 0.6, fileName: "Unsure Column - Fortnightly Improvement Goals" });
    }

    componentDidMount(){
        if(this.props.history.location.state.role === "junior"){
            this.setState({
                unsure: juniorCards
            })
        } else {
            this.setState({
                unsure: generalCards
            })
        }
    }

    render() {
        return (
            <div className="step-one content-padding">
                <div className="content-padding">
                    <div className="center-text">
                        <h4>Instructions:</h4>
                        <p>
                        • Take your time to distribute the cards to the columns below depending on how essential they will be to you <b>in the next two weeks.</b><br/>
                        • Take into account your role within your team, your career goals and all the other dimensions of your situation.<br/>
                        • If you aren't sure what a concept is or how important it will be to you over the next two weeks leave the card in the 'Unsure' column.
                    </p>
                    </div>
                    <p className="center-text">
                        Cards in <b style={{color: '#a4bdd6'}}>blue</b> are technical skills, cards in <b style={{color: '#b0a0d9'}}>purple</b> are general skills and cards in <b style={{color: '#bddfb3'}}>green</b> are cross-functional skills.
                    </p>
                    </div>

                    <div className="button-align">
                        <button onClick={this.exportPDFWithMethod}>Export Cards from Unsure Column</button>
                    </div> 

                    <div className="button-align">
                        <Link to={{
                            pathname: "/steptwo",
                            state: { unsure: this.state.essential },
                        }}><button>Next Step ></button></Link>
                    </div> 
                <div className="content">
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="unsure"
                            ref={(unsure) => this.unsure = unsure}>
                            {provided => (
                                <div
                                    ref={provided.innerRef}
                                    style={getListStyle}>
                                    <h2>Unsure</h2>
                                    {this.state.unsure.length ? (this.state.unsure.map((item, index) => (
                                        DragDropCard(item, index)
                                    ))) : ''}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                        <Droppable droppableId="niceToHave">
                            {provided => (
                                <div
                                    ref={provided.innerRef}
                                    style={getListStyle}>
                                    <h2>Nice To Have</h2>
                                    {this.state.niceToHave.length ? (this.state.niceToHave.map((item, index) => (
                                        DragDropCard(item, index)
                                    ))) : ''}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                        <Droppable droppableId="significant">
                            {provided => (
                                <div
                                    ref={provided.innerRef}
                                    style={getListStyle}>
                                    <h2>Significant</h2>
                                    {this.state.significant.length ? (this.state.significant.map((item, index) => (
                                        DragDropCard(item, index)
                                    ))) : ''}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                        <Droppable droppableId="essential">
                            {provided => (
                                <div
                                    ref={provided.innerRef}
                                    style={getListStyle}>
                                    <h2>Essential</h2>
                                    {this.state.essential.length ? (this.state.essential.map((item, index) => (
                                        DragDropCard(item, index)
                                    ))) : ''}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>

        );
    }
}

export default StepOne;